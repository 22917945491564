import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Note from '../../components/note';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>

    <h1 {...{
      "id": "wrapper-customization"
    }}>{`Wrapper customization`}</h1>
    <p>{`The `}<a parentName="p" {...{
        "href": "/getting-started#using-the-wrapper"
      }}>{`wrapper`}</a>{` component can be used
to set the layout for the MDX document.  It’s often used to set container
width, borders, background colors, etc.  However, it’s also unique because
it has access to the children passed to it.`}</p>
    <p>{`This means that you can do powerful things with the MDX document elements.
If you aren’t very familiar with React children, it might be worthwile to
start with `}<a parentName="p" {...{
        "href": "https://mxstbr.blog/2017/02/react-children-deepdive/"
      }}><em parentName="a">{`A deep dive into children in React`}</em></a>{`
by Max Stoiber.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We can render arbitrary components as children, but still control them from the
parent instead of the component we render them from.`}</p>
      <p parentName="blockquote"><em parentName="p">{`Max Stoiber - A deep dive into children in React`}</em></p>
    </blockquote>
    <p>{`The implications of this are very interesting from the context of an
MDX wrapper component.  This means the wrapper can do things like reordering
components, wrapping them, or even further customizing them.`}</p>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h2>
    <p>{`For the purposes of this guide we will use the following MDX:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`### Kicker

# Hello, world!

Working with React children is fun!
`}</code></pre>
    <h3 {...{
      "id": "using-the-wrapper-for-layout"
    }}>{`Using the wrapper for layout`}</h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`wrapper`}</inlineCode>{` element in MDXProvider to set layout and even
background color for your MDX documents.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.js
import React from 'react'

import {MDXProvider} from '@mdx-js/react'

const components = {
  wrapper: props => (
    <div style={{ padding: '20px', backgroundColor: 'tomato' }}>
      <main {...props} />
    </div>
  )
}

export default props => (
  <MDXProvider components={components}>
    <main {...props} />
  </MDXProvider>
)
`}</code></pre>
    <h3 {...{
      "id": "inspecting-types"
    }}>{`Inspecting types`}</h3>
    <Note>
  This only works on the latest alpha version @mdx-js/mdx@next (>= 1.0.0-alpha.7)
    </Note>
    <p>{`Sometimes you might want to inspect the element type of that
MDX will be rendering with its custom pragma.  You can use the
wrapper to achieve this because it will have access to the MDX
components as children.  You can check their type by accessing
the `}<inlineCode parentName="p">{`mdxType`}</inlineCode>{` in props.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.js
import React from 'react'

import {MDXProvider} from '@mdx-js/react'

const components = {
  wrapper: ({ children, ...props }) => {
    console.log(children.map(child => child.props.mdxType))
    return <>{children}</>
  }
}

export default props => (
  <MDXProvider components={components}>
    <main {...props} />
  </MDXProvider>
)
`}</code></pre>
    <h3 {...{
      "id": "manipulating-children"
    }}>{`Manipulating children`}</h3>
    <p>{`You can also manipulate and modify children.  Here is an example of reordering
them by converting them to an array and calling `}<inlineCode parentName="p">{`reverse`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.js
import React from 'react'

import {MDXProvider} from '@mdx-js/react'

const components = {
  wrapper: ({ children, ...props }) => {
    const reversedChildren = React.Children.toArray(children).reverse()
    return <>{reversedChildren}</>
  }
}

export default props => (
  <MDXProvider components={components}>
    <main {...props} />
  </MDXProvider>
)
`}</code></pre>
    <h2 {...{
      "id": "related"
    }}>{`Related`}</h2>
    <p>{`If you would like to dive deeper, check out
`}<a parentName="p" {...{
        "href": "https://mxstbr.blog/2017/02/react-children-deepdive/"
      }}><em parentName="a">{`A deep dive into children in React`}</em></a>{`
or Brent Jackson’s `}<a parentName="p" {...{
        "href": "https://github.com/jxnblk/mdx-blocks"
      }}>{`MDX Blocks`}</a></p>
    <hr></hr>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mxstbr.blog/2017/02/react-children-deepdive"
        }}>{`https://mxstbr.blog/2017/02/react-children-deepdive`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jxnblk/mdx-blocks"
        }}>{`https://github.com/jxnblk/mdx-blocks`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      